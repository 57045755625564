<template>
  <div justify="center">
    <v-icon
      small
      @click="openForm"
    >
      {{ icons.mdiEye }}
    </v-icon>

    <v-dialog
      v-model="dialog"
      :height="dialogHeight"
      scrollable
    >
      <v-card tile>
        <v-toolbar
          class="d-flex justify-end"
          height="36px"
          color="#E1F5FE"
        >
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card
          class="overflow-y-auto"
          height="100%"
          tile
        >
          <template v-if="apierror.status == 'error'">
            <div
              v-for="msg of apierror.messages"
              :key="msg"
            >
              <v-row class="ml-6 mb-3 ma-3">
                <span style="color: red">* {{ msg }} </span>
              </v-row>
            </div>
          </template>
          <v-container
            style="padding:0px;max-width:100% !important"
          >
            <v-img
              width="100%"
              position="relative"
              :src="subsForm.image ? subsForm.image : require('@/assets/images/noimage.jpg')"
            ></v-img>
            <div class="mb-12 pb-3" style="position: relative">
              <div
                style="
                  margin-top: 25px;
                  line-height: 80px;
                  text-align: center;
                  font-family: 'Noto Sans JP', sans-serif;
                  font-style: normal;
                  font-weight: 900;
                  font-size: 55px;
                  color: black;
                "
              >
                <span>シーズン{{ subsForm.subs_season }}</span>
                <br />
                <span> {{ subsForm.subs_name }} </span>
                <br />
                <span class="ml-6" style="font-size: 50px">{{ subsForm.subs_tip.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}円（税込み）</span>
              </div>
            </div>
            <div v-html="subsForm.subs_overview"></div>
            <div style="max-width: 1366px; margin: auto" class="mt-12">
              <v-row v-for="(line, index) in subsList" :key="index">
                <v-spacer></v-spacer>
                <v-col v-for="(item, i) in line" :key="i" cols="6" md="3" class="pa-5 pointer">
                  <v-img
                    alt="BimiStock（ビミストック）は全国の美味しい保存食を3か月毎お届けする普段使いの防災食定期便です。"
                    :src="item.image"
                    @click="openForm(item.id)"
                  ></v-img>
                  <div class="text" style="white-space: pre-line">
                    <span>{{ item.item_name }}</span>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>

              <div class="d-flex justify-center my-12">
                <v-btn depressed rounded style="background-color: #ff6e40; width: 60%; min-height: 80px">
                  <div
                    style="
                      color: #fff;
                      line-height: 37px;
                      font-family: 'Noto Sans JP', sans-serif;
                      font-style: normal;
                      font-weight: 900;
                      font-size: 20px;
                    "
                  >
                    <span>購入する</span>
                  </div>
                </v-btn>
              </div>
            </div>

            <div style="text-align: center; font-family: 'Noto Sans JP', sans-serif; font-style: normal; font-weight: 900">
              <span style="font-size: 80px; color: black; line-height: 200px">次回のお届け商品</span>
              <br />
              <span style="font-size: 50px; color: black">{{ bottomYear }}年{{ bottomMonth }}月末までに</span>
              <br />
              <span style="font-size: 50px; color: black">「シーズン{{ bottomSeason }}」の商品をご案内致します。</span>
              <br />
              <span style="font-size: 50px; color: black">どうぞお楽しみにお待ちください。</span>
            </div>
          </v-container>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiEye,
  mdiClose,
} from '@mdi/js'
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'

export default {
  props: ['id'],
  data: () => ({
    dialog: false,
    images: [],
    selectedImg: '',
    icons: {
      mdiEye,
      mdiClose,
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    subsList: [],
    bottomYear: '',
    bottomMonth: '',
    bottomSeason: '',
    subsForm: {
      subs_code: '',
      subs_name: '',
      saleable: '',
      subsDetails: [],
      subs_overview: '',
      subs_desc: '',
      image: '',
      subs_images: '',
      subs_up_show: '',
      subs_up_value: '',
      subs_dr_show: '',
      subs_dr_value: '',
      subs_tep_show: '',
      subs_tep: '',
      subs_ctr: '',
      subs_tip: '',
      subs_ssd: '',
      subs_sed: '',
      subs_season: '',
      subs_eos: '',
    },
  }),

  computed: {
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('subscriptionStore', ['selectedSubs', 'subscriptionItems']),

    dialogHeight() {
      return document.documentElement.clientHeight * 0.9
    },
  },

  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.clearData()
      }
    },
  },

  created() {
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('subscriptionStore', ['loadSubs', 'loadSubscriptionItems']),

    openForm() {
      this.loadData()
    },
    clearData() {
      this.subsList = []
      this.subsForm = {
        subs_code: '',
        subs_name: '',
        saleable: '',
        subsDetails: [],
        subs_overview: '',
        subs_desc: '',
        image: '',
        subs_images: '',
        subs_up_show: '',
        subs_up_value: '',
        subs_dr_show: '',
        subs_dr_value: '',
        subs_tep_show: '',
        subs_tep: '',
        subs_ctr: '',
        subs_tip: '',
        subs_ssd: '',
        subs_sed: '',
        subs_season: '',
        subs_eos: '',
      }
    },
    loadData() {
      console.log('load', this.id)
      this.setOverlayStatus(true)
      Promise.all([
        this.loadSubs(this.id),
        this.loadSubscriptionItems(this.id),
      ]).then(() => {
        this.subsForm = {
          ...this.selectedSubs,
        }
        console.log('subsForm', this.subsForm)
        if (this.subsForm.subs_images) {
          this.subsForm.image = `${this.subsForm.imgUrl_prefix}/${this.subsForm.subs_images.split('"')[1].replace(/^\//g, '')}`
        }

        if (this.subsForm.subs_season === 4) {
          this.bottomSeason = 1
          this.bottomMonth = 4
          this.bottomYear = this.subsForm.subs_year
        }
        if (this.subsForm.subs_season === 3) {
          this.bottomSeason = 4
          this.bottomMonth = 1
          this.bottomYear = this.subsForm.subs_year + 1
        }
        if (this.subsForm.subs_season === 2) {
          this.bottomSeason = 3
          this.bottomMonth = 10
          this.bottomYear = this.subsForm.subs_year
        }
        if (this.subsForm.subs_season === 1) {
          this.bottomSeason = 2
          this.bottomMonth = 7
          this.bottomYear = this.subsForm.subs_year
        }

        const Items = [...this.subscriptionItems]
        console.log('loadSubscriptionItems', this.subscriptionItems)
        this.subscriptionItems.forEach((si, i) => {
          if (si.images) {
            Items[i].image = `${si.imgUrl_prefix}/${si.images.replaceAll('"', '').split(',')[0].replace(/^\//g, '')}`
          }
        })
        const showStyle = this.subsForm.show_style
        if (showStyle) {
          const len = showStyle.length
          for (let i = 0; i < len; i += 1) {
            this.subsList.push(Items.splice(0, showStyle[i]))
          }
        }
      }).catch(error => {
        console.log('error', error)
        this.apierror.status = 'error'
        this.apierror.code = error.response.data.code
        this.apierror.messages = error.response.data.message
      }).finally(() => {
        this.setOverlayStatus(false)
        this.dialog = true
      })
    },
  },
}
</script>

<style scoped>
</style>
