<template>
  <div id="production-list">
    <v-card>
      <v-card-title>定期便（一覧）</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="subs_course"
            :items="courseNames"
            item_text="course_name"
            item_value="course_id"
            placeholder="定期便コース"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="subs_year"
            :items="subsYears"
            placeholder="年"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="subs_season"
            :items="subsSeason"
            placeholder="シーズン"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="saleable"
            :items="saleableStatus"
            placeholder="販売可否"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-menu
            v-model="dateStartMenu"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :nudge-right="40"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="販売開始日"
                dense
                hide-details
                readonly
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="販売終了日"
                dense
                outlined
                hide-details
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'subscription-create', params: { query: this.$route.query } }"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="subsList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.subs_name`]="{ item }">
          <label>
            {{ item.subs_name }}
          </label>
        </template>
        <!-- <template v-slot:[`item.subsSeason`]="{ item }">
          <label>
            {{ item.subs_year }}-S{{ item.subs_season }}
          </label>
        </template> -->
        <template v-slot:[`item.subsdate`]="{ item }">
          <label>
            {{ item.subs_ssd }} ~ {{ item.subs_sed }}
          </label>
        </template>
        <template v-slot:[`item.subs_image`]="{ item }">
          <v-img
            :src="item.subs_image ? item.subs_image : require('@/assets/images/noimage.jpg')"
            max-height="50"
            max-width="50"
            style="margin: 0 auto 0 0"
          ></v-img>
        </template>
        <template v-slot:[`item.saleable`]="{ item }">
          <label>{{
            getSaleable(item.saleable)
          }}</label>
        </template>
        <template v-slot:[`item.subs_tip`]="{ item }">
          <label>{{ item.subs_tip ? item.subs_tip.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : "" }}</label>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <SubscriptionPreview
              :id="item.subs_id"
              class="py-2 pl-1"
              @refresh="loadData"
            ></SubscriptionPreview>
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <DeleteSubscription
              :id="item.subs_id"
              class="py-2 pl-1"
              @refresh="loadData"
            ></DeleteSubscription>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/*eslint-disable*/
import { mdiCalendar, mdiMagnify, mdiPencil, mdiDelete, mdiExportVariant, mdiAutorenew, mdiPlus, mdiClipboardArrowUpOutline, mdiEye,mdiTextBoxSearchOutline} from '@mdi/js'
import { mapActions, mapState, mapMutations } from 'vuex'
import DeleteSubscription from '@/views/bimi/product/Subscription/SubscriptionDelete.vue'
import SubscriptionPreview from '@/views/bimi/product/Subscription/SubscriptionPreview.vue'
/*eslint-disable*/
export default {
  components: {
    DeleteSubscription,
    SubscriptionPreview,
  },
  data: () => ({
    arrBfo:[],
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    subs_name: '',
    subs_course: '',
    subCourse:[],
    subs_year: '',
    subs_season: '',
    subsSeason:[1,2,3,4],
    subs_code: '',
    saleable: '',
    search: '',
    saleableStatus: [
      {
        text: '販売不可', value: 0,
      },
      {
        text: '販売可', value: 1,
      },
    ],
    saleables: ['否', '可'],
    icons: {
      mdiCalendar,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiAutorenew,
      mdiPlus,
      mdiExportVariant,
      mdiClipboardArrowUpOutline,
      mdiEye,
      mdiTextBoxSearchOutline,
    },
    headers: [
      {
        text: 'イメージ',
        value: 'subs_image',
        align: 'left',
        width: '8%',
        sortable: false,
        fixed: true,
      },
      {
        text: 'ID',
        value: 'subs_id',
        width: '6%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '定期便コード',
        value: 'subs_code',
        width: '15%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '定期便名',
        align: 'left',
        sortable: true,
        value: 'subs_name',
        width: '15%',
        fixed: true,
      },
      {
        text: '販売',
        value: 'saleable',
        width: '8%',
        align: 'center',
        sortable: true,
        fixed: false,
      },
      {
        text: '価格',
        value: 'subs_tip',
        width: '8%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
      // {
      //   text: ' 年',
      //   value: 'subs_year',
      //   width: '5%',
      //   align: 'center',
      //   sortable: true,
      //   fixed: false,
      // },
      // {
      //   text: 'シーズン',
      //   value: 'subs_season',
      //   width: '10%',
      //   align: 'center',
      //   sortable: true,
      //   fixed: false,
      // },
       {
        text: 'シーズン',
        value: 'subsSeason',
        width: '10%',
        align: 'center',
        sortable: true,
        fixed: false,
      },
      // {
      //   text: '販売期間',
      //   value: 'subsdate',
      //   width: '12%',
      //   align: 'left',
      //   sortable: true,
      //   fixed: true,
      // },
      {
        text: '販売開始日',
        value: 'subs_ssd',
        width: '12%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
       {
        text: '販売終了日',
        value: 'subs_sed',
        width: '12%',
        align: 'left',
        sortable: true,
        fixed: true,
      },

      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '8%',
        align: 'center',
        fixed: true,
      },
      {
        text: '販売開始日',
        value: 'subs_ssd',
        align: ' d-none',
      },
      {
        text: '販売終了日',
        value: 'subs_sed',
        align: ' d-none',
      },
      {
        text: '販売年',
        value: 'subs_year',
        align: ' d-none',
      },
      {
        text: '販売シーズン',
        value: 'subs_season',
        align: ' d-none',
      },
    ],
    subsYears: []
  }),
  computed: {
    ...mapState('subscriptionStore', ['subsList', 'subsCodes','courseNames','courseMaster']),
    getSaleable() {
      return function(saleable) {
        if (saleable === 1) return '可'
        return '否'
      }
    },
  },
  watch: {
    subs_year(newValue) {
      this.changeRouteQuery('subs_year', newValue)
      this.loadData()
    },
    subs_season(newValue) {
      this.changeRouteQuery('subs_season', newValue)
      this.loadData()
    },
    subs_course(newValue) {
      let course_code = this.courseMaster[newValue].code
      this.changeRouteQuery('course_code', course_code)
      this.loadData()
    },
    subs_name(newValue) {
      console.log('come in')
      console.log('newValue',newValue)
      this.changeRouteQuery('subs_name', newValue)
      this.loadData()
    },
    subs_code(newValue) {
      this.changeRouteQuery('subs_code', newValue)
      this.loadData()
    },
    saleable(newValue) {
      if (typeof newValue === 'number') {
        this.changeRouteQuery('saleable', newValue.toString())
      } else {
        this.changeRouteQuery('saleable', newValue)
      }
      this.loadData()
    },
    startDate(newValue) {
      this.changeRouteQuery('startDate', newValue)
      this.loadData()
    },
    endDate(newValue) {
      this.changeRouteQuery('endDate', newValue)
      this.loadData()
    },
  },
  created() {
    this.setYears()
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  destroyed() {
    this.clearSubsList()
    this.clearSubsCodes()
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('subscriptionStore', ['loadSubsList', 'loadSubscriptionCodes','loadSubscriptionCourses']),
    ...mapMutations('subscriptionStore', ['clearSubsList', 'clearSubsCodes']),

    setYears(){
      let date = new Date();
      const toYear = date.getFullYear()+ 1 ;

      for (let i= 2022;i<=toYear; i++) {
        this.subsYears.push(i)
      }

    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadSubscriptionCodes(),
        this.loadSubscriptionCourses(),
        this.loadSubsList(this.$route.query),
      ]).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({ path: this.$route.path, query })
    },

    loadQuery() {
      this.subs_name = this.$route.query.subs_name ?? ''
      this.subs_code = this.$route.query.subs_code ?? ''
      this.subs_year = this.$route.query.subs_year ?? ''
      this.subs_season = this.$route.query.subs_season ?? ''
      this.subs_course = this.$route.query.subs_course ?? ''
      this.saleable = this.$route.query.saleable ? parseInt(this.$route.query.saleable) : ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },
    editItem(item) {
      console.log('item', item.subs_id)
      this.$router.push({ name: 'subscription-edit', params: { id: item.subs_id, query: this.$route.query }  })
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
